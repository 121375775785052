<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="所属影院" name="cinemaId">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="会员手机号" name="phone">
						<a-input placeholder="请输入" v-model:value="formState.phone"></a-input>
					</a-form-item>

				</a-row>

				<a-row v-show="isShow">

                    <a-form-item class="ui-form__item" label="次卡卡号" name="cardNumber">
						<a-input placeholder="请输入" v-model:value="formState.cardNumber"></a-input>
					</a-form-item>

                    <a-form-item class="ui-form__item" label="支付编号" name="outTradeNo">
						<a-input placeholder="请输入" v-model:value="formState.outTradeNo"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="订单编号" name="orderNo">
						<a-input placeholder="请输入" v-model:value="formState.orderNo"></a-input>
					</a-form-item>

                    <a-form-item class="ui-form__item" label="会员昵称" name="nickname">
						<a-input placeholder="请输入" v-model:value="formState.nickname"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="状态" name="status">
						<a-select placeholder="请选择" v-model:value="formState.status" style="width: 180px;">
							<a-select-option :value="1">已绑定</a-select-option>
							<a-select-option :value="2">已解绑</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="订单来源" name="source">
						<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">微信小程序</a-select-option>
							<a-select-option :value="2">抖音小程序</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['report_timesCard_publish_export']">
							<ExportReport type="timesCardVolume" :searchData="searchData"></ExportReport>
						</span>
						<!-- <a-button v-permission="['report_timesCard_publish_export']" type="primary" @click="onExport()" ghost>导出</a-button> -->
						<span v-if="!isShow" class="ui-showMore" @click="onDisplay">展开<Icon icon="DownOutlined"></Icon></span>
						<span v-else class="ui-showMore" @click="onDisplay">收起<Icon icon="UpOutlined"></Icon></span>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 2000 }" tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key == 'cinemaName'">
							<div>{{record.cinemaName || '--'}}</div>
						</template>
						
						<template v-if="column.key === 'source'">
							<div>
								<a-tag color="green" v-if="record.source === 1">
									微信小程序
								</a-tag>
								<a-tag color="blue" v-else> 抖音小程序 </a-tag>
							</div>
						</template>

						<template v-if="column.key == 'refundInfo'">
							<div v-if="record.status == 2">
								<div style="color: #ff0000;">已解绑</div>
								<div>退卡备注：{{record.refundCardRemark}}</div>
								<div>时间：{{transDateTime(record.refundCardTime)}}</div>
							</div>
							<div v-else>无</div>
						</template>
						
						<template v-if="column.key == 'cardName'">
							<div>{{record.cardName || '--'}}</div>
						</template>

						<template v-if="column.key == 'cardStatus'">
							<div>{{['绑定','解绑'][record.status-1]}}</div>
						</template>
						
						<template v-if="column.key == 'cardNumber'">
							<div>{{record.cardNumber || '--'}}</div>
						</template>

						<template v-if="column.key == 'wxNickname'">
							<div>{{record.nickname || '--'}}</div>
						</template>
						
						<template v-if="column.key == 'tradTime'">
							<div>{{transDateTime(record.createTime) || '--'}}</div>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getCardPublishReport } from '@/service/modules/report.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import ExportReport from '@/components/exportReport/exportReport.vue';

	export default {
		components: {
			Icon,
			ExportReport
		},
		data() {
			return {
				loading: false,
				formState: {
					source: 0,
					cardVolumeType: 1,//1.次卡 2.年卡
				},
				showModal: false,
				list: [],
				columns: [{
					title: '影院名称',
					key: 'cinemaName'
				},, {
					title: '订单编号',
					dataIndex: 'orderNo'
				}, {
					title: '支付编号',
					dataIndex: 'outTradeNo'
				}, {
					title: '交易时间',
					key: 'tradTime'
				}, {
					title: '订单来源',
					key: 'source',
					width: 120,
					align: 'center'
				}, {
					title: '次卡卡号',
					key: 'cardNumber'
				}, {
					title: '会员昵称',
					key: 'wxNickname'
				}, {
					title: '会员手机号',
					dataIndex: 'memberPhone'
				}, {
					title: '次卡等级',
					key: 'cardName'
				}, {
					title: '状态',
					key: 'cardStatus',
					align: 'center'
				}, {
					title: '退款信息',
					key: 'refundInfo'
				}, {
					title: '操作人',
					dataIndex: 'refundOperateUserName'
				} ],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				id: 0,
				searchData: [],
				isShow: false,
				organizationList: [],
				cinemaAllList: [],
			}
		},
		created(){
			// this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					
					...this.searchData,
				}
				this.loading = true;
				try {
					let {data} = await getCardPublishReport(postData);
					this.loading = false;
					this.list = data.list;
					this.pagination.total = data.totalCount;
				} catch (error) {
					this.loading = false;
					console.log(error);
				}
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(() => {

				})
			},
			onDisplay(){
				this.isShow = !this.isShow;
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
				
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = '';
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
	.ui-batch{
		margin-right: 10px;
	}
	.ui-showMore{
        color: #6699ff;
        margin-left: 10px;
		cursor: pointer;
    }
</style>
